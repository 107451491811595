export const User = {
    Auth: '/user/auth',
    GoogleAuth: '/user/googleauth',
    FacebookAuth: '/user/facebookauth',
    RefreshToken: '/user/refresh',
    Register: '/user/registerclient',
    ForgotPasswordSendCode: '/user/forgotpwdsendcode',
    ForgotPasswordConfirmCode: '/user/forgotpwdconfirm',
    ResetPassword: '/user/resetpassword',
    RevokeAccess: '/user/revoke',
    ConfirmEmail: '/user/confirmemail',
    ResendEmailConfirmationCode: '/user/resendconfirm',
    UpdateUser: '/user/update',
    DecryptConfirmationToken: '/user/decryptconfirmationtoken',
    List: '/user/list',
    Remove: '/user/remove',
    GetById: '/user/get'
};

export const Driver = {
    Update: '/driver/update',
    GetByUserId: '/driver/getbyuserid',
    StartPickingUp: '/driver/startPickingUp',
    StartPickingUpMorning: '/driver/startPickingUpMorning',
    StartPickingUpAfternoon: '/driver/startPickingUpAfternoon',
}

export const Client = {
    Update: '/client/update',
    GetByUserId: '/client/getbyuserid',
    GetWalletByUserId: '/client/getwalletbyuserid',
    GetWalletMinimum: '/client/getwithdrawminimum',
    GetCharities: '/client/getcharities',
    SendToCharity: '/client/sendToCharity',
    GetClientByOrder: '/client/getclientbyorder',
    NotifyClient: '/client/notifyclient',
}

export const Depot = {
    RegisterDepot: '/depot/register',
    PostalCodeList: '/depot/postalcodelist',
    SavePostalCode: '/depot/savepostalcode',
    SelectList: '/depot/selectlist',
    DepotList: '/depot/list',
    DepotMapList: '/depot/listmap',
    DisableDepot: '/depot/disable',
    EnableDepot: '/depot/enable',
    GetById: '/depot/getbyid',
    Update: '/depot/update',
    GetDepotInCoverage: '/depot/getdepotincoverage',
};

export const Config = {
    GetDaysLimit: '/config/getdayslimit',
    GetPercentageLimit: '/config/getpercentagelimit',
}

export const Schedule = {
    GetLimits: '/schedule/getlimits',
}

export const Order = {
    CreateOrder: '/order/createorder',
    GetOrders: '/order/getorders',
    GetById: '/order/getbyid',
    GetByClientId: '/order/getbyclientid',
    GetOrdersByDriverId: '/order/getordersbydriverid',
    ChangeStatus: '/order/changestatus',
    UploadPhoto: '/order/uploadphoto',
}
